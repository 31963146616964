import * as React from 'react';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import BgImage from '../../../static/sewingMachineCompressed.jpg';
import { Link, animateScroll as scroll } from "react-scroll";


export default function ProductHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundColor: '#7fc7d9', // Average color of the background image.
        backgroundPosition: 'center',
        backgroundImage: `url(${BgImage})`
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: 'none' }}
        src={`${BgImage}`}
        alt="increase priority"
      />
      <Typography color="inherit" align="center" variant="h2" marked="center" id="hero">
        {'Tailor ZhenYue'}
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        sx={{ mb: 4, mt: 5 }}
      >
        {'Providing bespoke tailoring services for over 25 years in Singapore'}
      </Typography>

      <Link
        activeClass="active"
        to="services"
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
      >
        <Button color="secondary"
          variant="contained"
          size="large"
          component="a"
          href="#services"
          sx={{ minWidth: 200 }} >
          Services Offered
        </Button>
      </Link>
      {/* <Typography variant="body2" color="inherit" sx={{ mt: 2 }}>
        Third Holding Text
      </Typography> */}
    </ProductHeroLayout>
  );
}
