import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';

import ContentCutIcon from '@mui/icons-material/ContentCut';
import BusinessIcon from '@mui/icons-material/Business';
import BedroomParentOutlinedIcon from '@mui/icons-material/BedroomParentOutlined';

const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

function Services() {
  return (
    <Box
      id="services"
      component="section"
      sx={{ display: 'flex', overflow: 'hidden', bgcolor: 'secondary.light' }}
    >
      <Container sx={{ my: 10, display: 'flex', position: 'relative' }}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography variant="h4" color="inherit" align="center" marked="center">
              {
                'Services'
              }
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <ContentCutIcon sx={{ fontSize: 40 }} />
              <Typography variant="h6" sx={{ mt: 5, mb: 3 }}>
                Alterations
              </Typography>
              <Typography variant="subtitle1">
                {
                  'Pants tapering and shortening, name badge sewing, zip replacement.'
                }
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <BedroomParentOutlinedIcon sx={{ fontSize: 40 }} />
              <Typography variant="h6" sx={{ mt: 5, mb: 3 }}>
                Home Furnishings
              </Typography>
              <Typography variant="subtitle1">
                {'Custom-made curtains and mattress cover, pillow and bolster cases.'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <BusinessIcon sx={{ fontSize: 40 }} />
              <Typography variant="h6" sx={{ mt: 5, mb: 3 }}>
                Events Decor
              </Typography>
              <Typography variant="subtitle1">
                {'Custom-made tablecloths and drapes, corporate uniform customization.'}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Services;
