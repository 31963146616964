import * as React from 'react';
import Customers from './modules/views/Customers';
import AppFooter from './modules/views/AppFooter';
import ProductHero from './modules/views/ProductHero';
import Services from './modules/views/Services';
import ContactDetails from './modules/views/ContactDetails';
import AppBar from './modules/views/AppBar';
import withRoot from './modules/withRoot';

function Index() {
  return (
    <React.Fragment>
      <AppBar />
      <ProductHero />
      <Services />
      <Customers />
      <ContactDetails />
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Index);
