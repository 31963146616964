import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '../components/Button';
import Typography from '../components/Typography';

import CallIcon from '@mui/icons-material/Call';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

const number = {
  fontSize: 24,
  fontFamily: 'default',
  color: 'secondary.main',
  fontWeight: 'medium',
  mb: 4
};

const image = {
  height: 55,
  my: 4,
};

function ContactDetails() {
  return (
    <Box
      component="section"
      sx={{ display: 'flex', bgcolor: 'secondary.light', overflow: 'hidden' }}
      id="contact us"
    >
      <Container
        sx={{
          mt: 10,
          mb: 15,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" marked="center" component="h2" sx={{ mb: 14 }}>
          CONTACT US
        </Typography>
        <div>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}>Contact Details</Box>
                <CallIcon sx={{ fontSize: 40 }} />
                <Typography variant="h5" align="center" sx={{ mt: 5, mb: 3 }}>
                  Call/SMS: <a href="tel:+6581784834" target={'_blank'} title="">8178 4834</a>
                  <br />
                  WhatsApp: <a href="https://wa.me/6581784834" target={'_blank'}>8178 4834</a>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}>Operating Hours</Box>
                <AccessTimeIcon sx={{ fontSize: 40 }} />
                <Typography variant="h5" align="center" sx={{ mt: 5, mb: 3 }}>
                  {'Monday - Saturday'}
                  <br />
                  {'11 am - 6 pm daily'}
                  <br />
                  {'Closed on Public Holidays'}

                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}>Location Details</Box>
                <LocationOnIcon sx={{ fontSize: 40 }} />
                <Typography variant="h5" align="center" sx={{ mt: 5, mb: 3 }}>
                  <a href="https://goo.gl/maps/MGjiP2oUcaKWNxhc9" target={'_blank'}>685 Race Course Road #01-330, Singapore 210685</a>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
        {/* <Button
          color="secondary"
          size="large"
          variant="contained"
          component="a"
          href="/premium-themes/onepirate/sign-up/"
          sx={{ mt: 8 }}
        >
          Get started
        </Button> */}
      </Container>
    </Box>
  );
}

export default ContactDetails;
